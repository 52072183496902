import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteDefaults from './useSiteDefaults'

const Seo = ({
  title,
  pageLayout,
  description,
  keywords, 
  noFollow,
  noIndex,
  seoTitle,
  url,
  slug
}) => {
  // Pull data from WordPress and Gatsby config
  const { settings, site } = useSiteDefaults()
  const wpSettings = settings.allSettings
  const fallback = site.siteMetadata

  const siteName = wpSettings.generalSettingsTitle || fallback.siteName
  const tagLine =
        wpSettings.generalSettingsDescription || fallback.description

  const browserTitle =
          seoTitle || (title
            ? `${title} – ${siteName}`
            : `${siteName} – ${tagLine}`)

  const getRobotsBySlug = (sl) => {
    if(sl === 'datenschutzerklaerung' || sl === 'impressum') {
      return 'noindex, follow'
    }
    return 'index, follow'
  }

  return (
    <Helmet
      bodyAttributes={{
        class: `mi-${pageLayout}`
      }}
    >
      {/* <title>{decodeEntities(browserTitle)}</title> */}
      <title>{browserTitle}</title>
      {description && (
        <meta name="description" content={description} />
      )}
      <meta name='robots' content={getRobotsBySlug(slug)} />
    </Helmet>

  )
}

export default Seo
